<template>
  <div class="homeBox">
    <mobileHeader />
    <div class="banner">
      <div style="position: relative" class="world">
        <img
          src="../../assets/mobile_word.jpg"
          style="width: 100%; height: 100%"
        />
        <div class="world-back">
          <img src="../../assets/world.png" alt="" style="width: 100%" />
          <div class="word-text">
            <div
              class="to-world"
              :class="{ toWorldEn: $i18n.locale === 'en-US' }"
            >
              {{ $t("lang.home.titleOne") }}
            </div>
            <div class="hope" :class="{ hopeEn: $i18n.locale === 'en-US' }">
              {{ $t("lang.home.titleTwo") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="about">
        <div class="aboutDetail">
          <div class="subTitle">
            {{ $t("lang.home.detail.subTitle") }}
          </div>
          <div class="container">
            {{ $t("lang.home.detail.container") }}
          </div>
          <!--          <router-link :to="{ path: '/aboutUs' }" class="tolearn">-->
          <!--            <div>{{ $t("lang.home.detail.more") }}<i class="triangle"></i></div>-->
          <!--          </router-link>-->
        </div>
      </div>
      <div class="ourService">
        <div class="ourcontainer">
          <div class="advantage">{{ $t("lang.home.advantage.title") }}</div>
          <div class="partadvantage">
            <div class="part">
              <div class="partimg">
                <img src="../../assets/m1.png" />
              </div>
              <div class="titles">
                {{ $t("lang.home.advantage.itemOne.title") }}
              </div>
              <span>{{ $t("lang.home.advantage.itemOne.desc") }}</span>
            </div>
            <div class="part">
              <div class="partimg">
                <img src="../../assets/m2.png" />
              </div>
              <div class="titles">
                {{ $t("lang.home.advantage.itemTwo.title") }}
              </div>
              <span>{{ $t("lang.home.advantage.itemTwo.desc") }}</span>
            </div>
            <div class="part">
              <div class="partimg">
                <img src="../../assets/m3.png" />
              </div>
              <div class="titles">
                {{ $t("lang.home.advantage.itemThree.title") }}
              </div>
              <span>{{ $t("lang.home.advantage.itemThree.desc") }}</span>
            </div>
          </div>
          <div class="partadvantage">
            <div class="part">
              <div class="partimg">
                <img src="../../assets/m4.png" />
              </div>
              <div class="titles">
                {{ $t("lang.home.advantage.itemFour.title") }}
              </div>
              <span>{{ $t("lang.home.advantage.itemFour.desc") }}</span>
            </div>
            <div class="part">
              <div class="partimg">
                <img src="../../assets/m5.png" />
              </div>
              <div class="titles">
                {{ $t("lang.home.advantage.itemFive.title") }}
              </div>
              <span>{{ $t("lang.home.advantage.itemFive.desc") }}</span>
            </div>
          </div>
          <!--        <router-link :to="{ path: '/customerService' }" class="ourser">-->
          <!--          <div>{{ $t("lang.home.advantage.service") }}<i class="triangle"></i></div>-->
          <!--        </router-link>-->
        </div>
      </div>
    </div>
    <mobile-footer />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "home",
  components: {
    mobileHeader,
    mobileFooter,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.homeBox {
  .banner {
    width: 100%;

    .world {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }

      .world-back {
        position: absolute;
        width: 320px;
        top: 110px;
        display: flex;
        align-items: center;
        justify-content: center;

        .word-text {
          position: absolute;
          text-align: center;

          .to-world {
            color: #fff;
            font-size: 30px;
            font-weight: bold;
            text-shadow: 0 0 25px #153a72;
          }

          .toWorldEn {
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            text-shadow: 0 0 25px #0a1d45;
            font-family: "English-Bold", serif;
          }

          .hope {
            color: #fff;
            font-size: 10px;
            letter-spacing: 2px;
            margin-top: 6px;
            text-shadow: 0 0 15px #001d58;
          }

          .hopeEn {
            color: #fff;
            font-size: 10px;
            margin-top: 10px;
            letter-spacing: 0;
            text-shadow: 0 0 15px #001d58;
          }
        }
      }
    }
  }

  .content {
    .about {
      margin: 0 auto;
      padding: 40px 25px 50px;
      display: flex;
      flex-direction: row;
      .aboutDetail {
        width: 610px;
        .title {
          font-size: 36px;
          font-weight: bold;
        }
        .subTitle {
          font-size: 18px;
          font-weight: bold;
          margin: 10px 0;
        }
        .container {
          font-size: 16px;
          margin-top: 20px;
          color: #6d7276;
        }
        .tolearn {
          width: 160px;
          padding: 17px 35px 18px;
          display: block;
          text-align: left;
          border: 2px solid #279cd4;
          border-radius: 6px;
          color: #279cd4;
          transition: all 0.2s ease 0s;
          position: relative;
          margin-top: 57px;
          cursor: pointer;
          font-size: 14px;
          text-decoration: none;
          .triangle {
            background: url("../../assets/arrow.png");
            background-size: contain;
            position: absolute;
            top: 24px;
            right: 33px;
            width: 6px;
            height: 8px;
            line-height: 0;
            border-width: 6px 4px;
            font-size: 0;
            overflow: hidden;
            font-weight: normal;
          }
        }
        .tolearn:hover {
          background-color: #279cd4;
          color: #ffffff;
          .triangle {
            color: #ffffff;
            width: 6px;
            height: 8px;
            background: url("../../assets/arrowhite.png");
            background-size: contain;
            margin-right: 5px;
          }
        }
      }
    }
    .ourService {
      padding: 60px 25px;
      background: url("../../assets/f_back.jpg");
      background-size: 100%;
      position: relative;
      .ourcontainer {
        position: relative;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        transform: translateX(-50%);
        z-index: 99;
        .advantage {
          font-size: 25px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 20px;
        }
        .partadvantage {
          .part {
            .partimg {
              margin: 45px 0 20px;
              width: 100px;
              img {
                width: 100%;
              }
            }
            .titles {
              font-size: 24px;
              font-weight: bold;
              color: #ffffff;
              line-height: 28px;
            }
            span {
              font-size: 14px;
              letter-spacing: 0;
              line-height: 26px;
              display: inline-block;
              text-align: justify;
              color: #ffffff;
              margin-top: 15px;
            }
          }
        }
      }
      .ourser {
        width: 160px;
        padding: 17px 35px 18px;
        display: block;
        text-align: left;
        border: 2px solid #ffffff;
        border-radius: 6px;
        font-size: 14px;
        color: #ffffff;
        transition: all 0.2s ease 0s;
        position: relative;
        margin-top: 57px;
        cursor: pointer;
        text-decoration: none;
        .triangle {
          background-size: contain;
          position: absolute;
          top: 24px;
          right: 33px;
          width: 6px;
          height: 8px;
          line-height: 0;
          border-width: 6px 4px;
          font-size: 0;
          overflow: hidden;
          font-weight: normal;
          background: url("../../assets/arrowhite.png");
          background-size: contain;
        }
      }
      .ourser:hover {
        background: #279cd4;
        color: #ffffff;
        border-color: #279cd4;
        .triangle {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
